
/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/init-iframe-popup';
import './modules/init-sliders';
import './modules/mobile-navigation';
import './modules/load-more-ajax';
